"use client";
import React, { useEffect, useState } from "react";
import styles from "@aspen/theme/saas/market.module.less";
import {
  BENCHMARK_CURRENCY_SYMBOL,
  COIN_CODE,
  convertUSD2USDC,
  decimalPointNoMoreX,
  filterSymbolsList,
  getCurrentColor,
  getReferenceCurrencyBySystem,
  i18nUtil,
  numberToThousands,
  Saas_Market_Fail,
  Saas_Market_Rise,
  systemDetection
} from "@aspen/libs";
import { CoinImage, WithCoinList, WithFiatAndSpotTrade } from "@aspen/ui";
import { Table } from "antd";
import Image from "next/image";
import {
  activeSymbolPrecisions,
  marketSymbolsList,
  symbolNames,
  useAppSelector
} from "@aspen/store";

interface IProps {
  getCoinFullName: (coinCode: string) => string;
}

const Market: React.FC<IProps> = (props) => {
  const intl = i18nUtil.t();
  const system = systemDetection();
  const allSymbolNamesList = filterSymbolsList(
    getReferenceCurrencyBySystem(system),
    useAppSelector(symbolNames)
  );
  const marketSymbolsInfo = useAppSelector(marketSymbolsList);
  const marketSymbolsPrecisions = useAppSelector(activeSymbolPrecisions);
  const dataSource = allSymbolNamesList.map((item) => marketSymbolsInfo[item]);

  const [randomImg, setRandomImg] = useState([]);
  useEffect(() => {
    let arr: any = [];
    dataSource.map((item, index) => {
      if (item.riseAndFall < 0) {
        arr.push(Saas_Market_Fail[Math.floor(Math.random() * Saas_Market_Fail.length)]);
      } else {
        arr.push(Saas_Market_Rise[Math.floor(Math.random() * Saas_Market_Rise.length)]);
      }
    });
    setRandomImg(arr);
  }, []);

  const columns = [
    {
      title: intl["saas.column.crypto"],
      dataIndex: "coinCode",
      render: (value, record) => {
        return (
          <div className={styles.coinContent}>
            <div style={{ width: 30 }}>
              <CoinImage size={30} showOpacity coins={value.split("_")[0]} />
            </div>
            <p className={styles.coinName}>
              <span className={styles.coinNameBold}>{convertUSD2USDC(value.split("_")[0])}</span>
              <span>{` / ${props.getCoinFullName(value.split("_")[0])}`}</span>
            </p>
          </div>
        );
      }
    },
    {
      title: intl["saas.column.price"],
      dataIndex: "priceLast",
      render: (value, record) => (
        <div>
          {BENCHMARK_CURRENCY_SYMBOL}{" "}
          {numberToThousands(
            decimalPointNoMoreX(value, marketSymbolsPrecisions[record.coinCode]?.symbolPrecision)
          )}
        </div>
      )
    },
    {
      title: intl["saas.column.trends"],
      render: (_, record, index) =>
        randomImg[index] ? (
          <Image alt="" unoptimized src={randomImg[index]} width={100} height={40} />
        ) : null
    },
    {
      title: intl["saas.column.24Change"],
      dataIndex: "riseAndFall",
      render: (value) => (
        <p className={styles[getCurrentColor(value)]}>
          {Number(value) > 0 ? "+" : null}
          {value}%
        </p>
      )
    }
  ];

  useEffect(() => {
    localStorage.setItem(COIN_CODE, "BTC_USD");
  }, []);

  return (
    <section className="saasCustomContainer">
      <p className={styles.headerText}>{intl["saas.market"]}</p>
      <p className={styles.subTitle}>{intl["saas.market.subTitle"]}</p>
      <p className={styles.subTitle2}>{intl["saas.powered.tips"]}</p>
      <div className={styles.container}>
        <Table
          columns={columns}
          dataSource={dataSource}
          // @ts-ignore
          rowKey={(record) => record?.coinCode}
          pagination={false}
        />
      </div>
    </section>
  );
};
export const PageMarket = WithFiatAndSpotTrade(WithCoinList(Market), {
  needSymbols: true,
  needWebsocket: true
});
